import QuoteLeft from 'assets/images/quote-left.svg';

import YesIcon from 'assets/images/yes-icon.svg';

import SuccessImage from 'assets/images/create-success.svg';
import CancelRenewalImage from 'assets/images/cancel-renewal-v2.svg';
import CanceSubslImage from 'assets/images/sad-smiley.svg';
import ThumbsupImage from 'assets/images/thumbsup-smiley.svg';

import Logo from 'assets/images/logo.svg';
import subscribiliLogo from 'assets/images/logo-subscribili.png'
import Theme from 'theme';

const {
  REACT_APP_GROUP_ID: groupId,
  REACT_APP_CLIENT_HEADER: clientHeader = groupId,
} = process.env;

const config = {
  groupId,
  logo: Logo,
  cptCodesIcon: YesIcon,
  portalName: 'Ideal Dental',
  tokenName: 'idealdentalSubscriberToken',
  globalClass: 'idealdental-container',
  clientHeader,
  enableHeroButton: true,
  pricingType: 'standard-pricing',
  headerProps: {
    enable: true,
  },
  homeTemplateProps: {
    heroSection: {
      variant: 2,
      titleClasses: 'home-hero-title',
      subTitleClasses: 'home-hero-subtitle',
      primaryButtonProps: {
        to: '/find-a-provider',
      },
      secondaryButton: 'Login',
      secondaryButtonProps: {
        to: '/login',
      },
      background: '/images/home-hero-header.webp',
    },
    benefitsSection: {
      variant: 2,
      columns: 3,
      alignTitle: 'center',
      singleLineHeader: true
    },
    ribbonSection: {
      enable: true,
      ribbonOrder: 2
    },
    descriptionSection: {
      enable: true,
      mobileImageUrl: '/images/home-images/description-bg.webp',
      desktopImageUrl: '/images/home-images/description-bg.webp'
    },
    subscriptionSection: {
      tabVariant: 2,
      carouselResponsiveItems: {
        desktop: 3,
      },
      planCardProps: {
        buttonProps: {
          normalVariant: 'contained',
          highlightedVariant: "contained",
          customSpace: 12
        }
      }
    },
    howItWorksSection: {
      enable: false,
      variant: 4,
      desktopHeroImg: '/images/home-images/how-it-works-bg.webp',
    },
    testimonialSection: {
      variant: 3,
      quoteImage: QuoteLeft,
      messageType: 'h6',
      gridSpacing: {
        xSmall: 12,
        medium: 12,
      },
      carouselResponsiveItem: {
        superLarge: 1,
        large: 1,
      },
    },
  },
  contactUsSection: {
    heroSection: {
      variant: 1,
      heroImage: '/images/contact-us-bg.webp'
    }
  },
  checkoutSection: {},
  findProviderSection: {},
  choosePlanSection: {
    sectionSubtitle: '',
    locationLogo: Logo,
    cardVariant: 2,
    tabVariant: 2,
    tabLabel: {
      annual: 'Billed Annually',
      annual_billed_monthly: 'Billed Monthly'
    },
    planCardProps: {
      buttonProps: {
        normalColor: 'primary',
        highlightedColor: 'primary',
        normalVariant: "contained",
        highlightedVariant: "outlined",
      }
    },
    staticHeroSection: {
      enable: true,
      variant: 1,
    },
  },
  subscriptionSuccess: {
    successImage: SuccessImage,
    enableBookAppointment: false,
  },
  savingsSummary: {
    enableFooterbar: true,
    heroSection: {
      variant: 1,
      heroImage: '/images/savings-banner-img.webp',
    },
    showCustomHeroVariant: true,
    showHowItWorks: true,
    moreSavingsButtonProps: {
      variant: 'outlined',
      color: 'primary'
    },
  },
  faqSection: {
    heroProps: {
      secondaryButtonProps: {
        variant: 'outlined',
        color: 'primary',
      },
    },
  },
  dashboardProps: {
    showBenefitsUsage: true,
    cancelRenewal: {
      image: CancelRenewalImage,
    },
    cancelSubscription: {
      image: CanceSubslImage,
    },
    reactivateRenewal: {
      image: ThumbsupImage,
    },
  },
  footer: {
    enable: true,
    logo: Logo,
    subscribiliLogo: subscribiliLogo
  },
  loginPage: {
    bannerImage: './images/login.webp',
  },
  theme: Theme
};

export default config;
