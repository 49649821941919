import { palette } from './palette';
import background from 'assets/images/faq-banner-img.webp';
import contactUsBg from 'assets/images/contactUs-bg.webp';
import savingsBg from 'assets/images/savings-banner-img.webp';
import homeHeroBg from 'assets/images/home-hero-header.webp';

export const homeStyle = {
  '.drawer-wrapper': {
    '& .drawer-title-container, .drawer-close-button': {
      color: palette.text.highlight,
    },
  },
  '.popup-action-button': {
    color: palette.primary.contrastText,
  },

  /* Login - Styles */
  '.login-root': {
    '& .hero-shaped__wrapper': {
      '& .hero-shaped__left-side': {
        flex: '0 0 60%',
        maxWidth: '60%',

        '@media(min-width: 900px)': {
          flex: '0 0 50%',
          maxWidth: '50%',
        },

        '& .hero-shaped__image': {
          width: '60vw',

          '@media(min-width: 900px)': {
            width: '50vw',
          },
        },
      },
    },
  },

  '& .header-wrapper': {
    borderStyle: 'solid',
    borderWidth: '0px 0px 8px 0',
    borderColor: palette.borderColor,
  },

  '.idealdental-container': {
    /* App main - styles */
    '& .app-main-content': {
      paddingTop: 0,
      '& .app-main-box': {
        background: palette.background.description
      }
    },

    '& .savings-hero-section, .checkout-hero-section, .plan-selection-section, .dashboard-hero-section, .find-provider-section, & .terms-hero-section, & .privacy-hero-section, & .hipaa-hero-section':
      {
        background: palette.background.header,
        marginTop: '60px',
        '& .hero-header-title, .hero-header-subtitle': {
          color: palette.text.highlight,
        },
      },

    '& .link': {
      color: palette.text.primary,
      fontSize: '20px'
    },

    /* Hero Section - Styles */
    '& .home-hero-wrapper': {
      backgroundImage: `url(${homeHeroBg})`,
      backgroundPosition: 'center right',
      backgroundSize: '1200px auto',
      backgroundRepeat: 'no-repeat',
      marginTop: '70px',
      backgroundColor: palette.text.highlight,

      '@media (min-width: 900px)': {
        paddingTop: '0 !important',
      },

      '& .hero-background': {
        objectFit: 'cover',
        '@media screen and (max-width: 900px)': {
          height: '340px',
        },
      },
      '& .image': {
        '@media (min-width: 900px)': {
          display: 'none',
        },
      },
      '@media (max-width: 900px)': {
          display: 'flex',
          flexDirection: 'column-reverse',
          paddingTop: 60,
          backgroundImage: 'none',
      },
      '& .hero-section': {
        display: 'flex',
        alignItems: 'center',
        maxHeight: '550px',
        width: '100%'
      },
      '& .section-header': {
        '@media screen and (min-width: 900px)': {
          marginTop: '60px',
        },
      },
      '& .home-hero-title': {
        fontSize: 52,
        color: palette.text.primary,
        maxWidth: '550px',
      },
      '@media screen and (max-width: 768px)': {
        '& .home-hero-title': {
          fontSize: '28px',
          color: palette.text.primary,
        },
        '& .home-hero-subtitle': {
          fontSize: '20px !important',
          maxWidth: 550,
        },
        '& .section-alternate__content': {
          padding: '10px 16px',
          paddingBottom: 40
        },
      },
      '& .section-header__subtitle-wrapper': {
        margin: '12px 0px',
      },
      '& .home-hero-subtitle': {
        fontSize: '28px',
        maxWidth: 550,
      },

      '& .login-button': {
        '&:hover': {
          borderColor: palette.secondary.main,
          backgroundColor: palette.secondary.main,
          color: palette.text.highlight,
        },
      },
    },

    '& .footer-wrapper': {
      background: palette.background.description,
      color: palette.text.primary,
      '& .footer-link, .copyrights-footer-link': {
        color: palette.text.primary,
      },
      '& .copyrights-footer': {
        borderTop: `1px solid ${palette.divider}`,
      },
    },

    /* Benefits - Styles */
    '& .definition-Wrapper': {
      background: palette.secondary.main,
      '& .section-header__title, .section-header__subtitle': {
        color: palette.text.highlight,
      },
      '& #definition-section': {
        '@media(max-width: 900px)': {
          paddingTop: '60px',
          '& .section-header__subtitle': {
            fontSize: '16px',
          },
        },

        '& .section-header': {
          '@media(min-width: 900px)': {
            paddingTop: 50,
            paddingBottom: 50,
          },
        },

        '& .definition-service-tile': {
          padding: (32, 32, 16),

          '& .definition-service-innertile': {
            '& .definition-service-title': {
              color: palette.text.highlight,
              fontSize: '50px',
              fontWeight: 'bold',
            },
            '& .definition-service-description': {
              fontSize: '24px',
              color: palette.text.highlight,
              paddingBottom: 48,
            },
          },
        },
      },
    },

    /* Pricing & Benefits / Subscription - Styles */
    '& .subscription-wrapper': {
      '& .subscription-bg': {
        height: '100%',
        backgroundColor: palette.background.main,
      },
      '@media screen and (max-width: 768px)': {
        '& .summary-text': {
          fontSize: '16px',
        },
      },
      '& #pricing-benefit-section': {
        '& .planCard': {
          border: 'none',
          '&:hover': {
            background: palette.text.highlight,
            border: `1px solid ${palette.primary.main}`,
            color: palette.text.primary,
          },
          '& .highlight-divider': {
            backgroundColor: palette.text.disabled,
          },
          '& .customBenefitsContainer': {
            '& .bold': {
              fontWeight: 'bold',
            },
          }
        },
        '& .select-plan-button': {
          '&:hover': {
            background: `${palette.primary.main} !important`,
          },
        },

        '& .subscription-section': {
          '& .section-header': {
            maxWidth: 720,

            '@media(min-width: 900px)': {
              margin: '0px auto 32px',
            },
          },
        },
        '& .savings-summary-link': {
          color: palette.text.primary,
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      },
    },

    /* Ribbon styles */
    '& .customRibboncontainer': {
      marginTop: 0,
      marginBottom: 0,
      paddingTop: '90px',
      paddingBottom: '90px',
      display: 'flex',
      flexGrow: 1,
      textAlign: 'center',
      padding: '16px',
      height: '514px',
      background: palette.primary.main,
      justifyContent: 'space-evenly',
      flexDirection: 'column',
      '& .customRibbonTitle': {
        fontSize: '74px',
        fontWeight: 600,
        lineHeight: '74px',
      },
      '& .customRibbonSubtitle': {
        paddingTop: '20px',
        paddingBottom: '20px',
        fontWeight: 'bold',
        color: palette.text.highlight,
      },
      '& .ribbonButtonGrid': {
        maxWidth: '220px',
        '& .customRibbonButton': {
          height: '64px',
          width: '219px',
          border: `2px solid ${palette.text.highlight}`,
          color: palette.text.highlight,
        },
        '& :hover': {
          background: palette.secondary.main,
        },
      },
      '@media(max-width: 900px)': {
        '& .customRibboncontainer': {
          flexDirection: 'column',
          alignItems: 'center',
          height: '320px',
        },
        '& .customRibbonTitle': {
          fontSize: '50px',
          fontWeight: '500',
          lineHeight: '50px'
        },
        '& .customRibbonSubtitle': {
          fontSize: '28px'
        },
        '& .customRibbonButton': {
          height: '54px',
          width: '159px',
        },
      },
    },

    '& .savings-contact-card': {
      '& .contact-title': {
        paddingBottom: '10px',
        fontSize: '38px'
      },
      '& .contact-subtitle': {
        paddingBottom: '10px',
        fontSize: '19px'
      },
      '& .primary-contact-button, .secondary-contact-button': {
        height: '62px',
        width: '210px !important',
        fontSize: '16px',
        border: `2px solid ${palette.text.highlight} !important`,
        '& .MuiButton-endIcon': {
          display: 'none'
        }
      },
      '& .primary-contact-button': {
        marginRight: '50px'
      },
      '@media(max-width: 900px)': {
        '& .primary-contact-button': {
          marginRight: '0px',
          marginBottom: '40px'
        },
        '& .section-header__cta-container': {
          display: 'flex',
          flexDirection: 'column'
        }
      },
    },

    /*Description Styling */
    '& .descriptionRootContainer': {
      borderBottom: `13px solid ${palette.text.primary}`,
      '& .customDesktopGridImg': {
        marginTop: -70
      },
      '@media(max-width: 900px)': {
        background: 'none',
        '& .how-cover-image': {
          borderBottom: `13px solid ${palette.text.primary}`,
        },
        '& .customDesktopGridImg': {
          marginTop: -40
        }
      },
    },
    '& .customContentHeader': {
      fontSize: '38px',
      maxWidth: '600px',
    },
    '& .customInnerContentHeader': {
      fontWeight: 'bolder',
      fontSize: '20px',
      color: palette.text.description,
    },
    '& .customDescription': {
      fontWeight: 300,
    },

    '& .descriptionButton': {
      fontSize: '18px',
      height: '62px',
      width: '240px',
      border: `2px solid ${palette.text.highlight}`,
    },

    '& .customContentGrid': {
      marginTop: '42px',
    },

    '@media(max-width: 900px)': {
      '& .customOverlapImageContainer': {
        bottom: '36px',
      },
      '& .customContentGrid': {
        padding: '0px 30px !important',
        marginTop: '40px',
      },
      '& .customContentHeader': {
        marginTop: '0px !important',
      },
    },

    /* Testimonial - Styles */
    '& .testimonial-wrapper': {
      background: palette.background.main,
      '& .section-header__overline-wrapper': {
        color: palette.primary.main,
      },

      '& .testimonial-title-wrapper': {
        '& .section-header__title': {
          maxWidth: 500,
        },
      },
      '& .testimonial-name': {
        color: palette.text.primary,
      },
      '& .testimonial-container': {
        flexDirection: 'column-reverse',

        '@media(min-width: 900px)': {
          flexDirection: 'row-reverse',
        },
      },
      '& .testimonial-content-wrapper': {
        marginTop: 10,
        '@media(min-width: 900px)': {
          marginTop: -200,
        },

        '& .react-multi-carousel-track': {
          paddingTop: 60,
          '@media(min-width: 900px)': {
            paddingTop: 190,
          },
        },
        '& .react-multi-carousel-dot--active button': {
          backgroundColor: palette.text.highlight,
        },
        '& .testimonial-message': {
          color: palette.text.primary,
          fontWeight: 400,
        },
      },
      '& .image-wrapper': {
        display: 'flex',
        justifyContent: 'center',

        '@media(min-width: 900px)': {
          justifyContent: 'left',
        },

        '& .image': {
          height: '100%',
          width: 'auto',

          '@media(min-width: 900px)': {
            height: 414,
          },
        },
      },
    },

    /* Static hero banner */
    '& .home-static-hero-wrapper': {
      paddingBottom: 80,

      '& .hero-static-bg': {
        backgroundColor: 'unset',
      },
    },

    /* Savings Summary */
    '& .savings-wrapper': {
      '& .home-hero-wrapper': {
        marginTop: 0,
        backgroundImage: `url(${savingsBg})`,
        backgroundColor: palette.background.light,
        backgroundPosition: 'top right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '65% auto',

        '& .contactHeaderTitle': {
          fontSize: '50px',
          fontWeight: 600,
          marginBottom: '20px',
          maxWidth: '200px',
          color: palette.text.primary
        },
        '& .contactHeaderSubtitle': {
          fontWeight: 300,
          fontSize: '28px',
          marginBottom: '30px',
          color: palette.text.primary
        },
        '& .customSubtitleItems': {
          fontSize: '18px',
        },
        '& .image-wrapper': {
          '& .image': {
            '@media (min-width: 900px)': {
              display: 'none',
            },
          },
        },

        '@media (max-width: 900px)': {
          '& .hero-left-section': {
            display: 'flex',
            marginTop: '-48px',
            zIndex: 1,
            padding: 7,
          },
          backgroundImage: 'none',
          marginTop: '50px',
          '& .image-wrapper': {
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            marginTop: '15px',
          },
          '& .section-alternate__content': {
            padding: '0px 16px',
            background: palette.background.light,
          },
        },
      },

      '& .customWorkingStepsContainer': {
        '& .section-header__title-wrapper': {
          padding: '0',
        },
      },

      '& .section-header': {
        marginBottom: '40px',
      },

      '& .hero-title': {
        fontSize: '65px'
      },

      '& .imageGridContainer': {
        height: '662px',
        '@media(max-width: 747px)': {
          '& .header-contact-wrapper': {
            marginTop: '60px',
            backgroundPosition: 'top right',
            backgroundSize: '110% auto',
          },
          marginBottom: '0px !important'
        },
        '& .contactHeaderTitle': {
          fontSize: '44px !important',
          fontWeight: 400,
          maxWidth: '100% !important'
        },
        '& .contactHeaderSubtitle': {
          marginBottom: '0px !important'
        },
        '& .customTitleInnerContainer': {
          marginTop: '200px',
          padding: '15px'
        }
      },
      '& .faq-contact-header-section': {
        minHeight: '600px',
      },
      '& .hero-section': {
        '& .section-header__title-wrapper': {
          marginTop: '80px',
          maxWidth: '300px',
        },
        '& .section-header__subtitle': {
          maxWidth: '500px',
          fontSize: '28px',
        },
        '@media (max-width: 900px)': {
          '& .section-header__title-wrapper': {
            maxWidth: '100%',
            marginTop: '10px',
            padding: 10,
            '& .section-header__title': {
              fontSize: '40px',
            },
          },
        },
      },
      background: palette.text.primary,
      color: palette.text.highlight,
      '& .savingsDataContainer': {
        '& .section-header__title, .section-header__subtitle': {
          display: 'flex',
          justifyContent: 'center',
          color: palette.text.highlight,
        },
        '& .section-header': {
          marginBottom: '40px',
        },
      },
      '& .view-more-button': {
        background: palette.text.highlight,
        border: `1px solid ${palette.text.highlight}`,
        color: palette.text.primary,
        '&:hover': {
          background: palette.primary.main,
          color: palette.text.highlight,
        },
      },
      '& .service-plan-table .savings-subheader': {
        backgroundColor: palette.text.highlight,
        fontWeight: 600,
      },
      '& .savings-topheader': {
        color: `${palette.text.primary} !important`,
        fontWeight: '500',
      },
      '& .savings-contact-card': {
        background: palette.background.main,
        marginTop: 0,
        backgroundColor: `${palette.primary.main} !important`,
        '& .contact-title': {
          fontSize: '38px',
        },
        '& .contact-subtitle': {
          fontSize: '19px',
        },
        '& .contact-title, .contact-subtitle': {
          color: palette.text.highlight,
        },
        '& .section-header': {
          marginTop: '10px !important',
          '& .howitworks-title': {
            '& .section-header__title-wrapper': {
              padding: '0 !important',
            },
          },
        },
        '& .primary-contact-button, .secondary-contact-button': {
          border: `1px solid ${palette.text.highlight}`,
          width: '150px',
        },
      },
      '@media (max-width: 900px)': {
        '& .section-header__title': {
          fontSize: '34px',
        },
        '& .section-header__subtitle': {
          fontSize: '18px',
        },
        '& .savingsDataContainer': {
          '& .section-header__title-wrapper': {
            display: 'flex !important',
            justifyContent: 'center !important',
          },
          '& .section-header__title': {
            fontSize: '24px',
            color: palette.text.highlight,
          },
          '& .section-header__subtitle': {
            fontSize: '15px',
            textAlign: 'center',
          },
        },
      },

      '& .howItWorksRoot': {
        '& .section-header': {
          marginBottom: '0 !important',
        },
        '& .section-header__title': {
          fontSize: '38px',
          color: palette.primary.main,
          marginBottom: '10px',
          fontWeight: 600,
        },
        '& .section-header__subtitle': {
          fontSize: '19px',
          marginBottom: '10px',
          color: `${palette.text.secondary} !important`,
        },
        '& .cover-image-wrapper': {
          padding: '15px',
        },
        '& .step-title': {
          fontWeight: 600,
          marginBottom: '20px',
          color: palette.text.primary,
        },
        '& .step-content': {
          marginBottom: '20px',
        },
      },
      '& .footer-bar-text': {
        fontSize: '19px',
        fontWeight: 400,
      },
    },

    /*Contact Us styling */
    '& .contactUsRoot': {
      '& .home-hero-wrapper': {
        marginTop: '10px',
        backgroundImage: `url(${contactUsBg})`,
        backgroundColor: palette.background.light,
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '62% auto',

        '& .contactHeaderTitle': {
          fontSize: '50px',
          fontWeight: 600,
          marginBottom: '20px'
        },
        '& .contactHeaderSubtitle': {
          fontWeight: 300,
          fontSize: '28px',
          marginBottom: '30px'
        },
        '& .customSubtitleItems': {
          fontSize: '18px',
        },
        '@media (max-width: 747px)': {
          backgroundSize: '110% auto',
          '& .customTitleInnerContainer': {
            marginTop: '220px',
          },
          '& .section-alternate__content': {
            paddingTop: 0,
          },
        },
        '& .section-header': {
          marginTop: 225
        },
        '& .image-wrapper': {
          '& .image': {
            '@media (min-width: 900px)': {
              display: 'none',
            },
          },
        },

        '@media (max-width: 900px)': {
          '& .hero-section': {
            maxHeight: '100%'
          },
          '& .hero-left-section': {
            display: 'flex',
            zIndex: 1,
          },
          '& .section-header': {
            padding: '5px',
            marginTop: '-100px'
          },
          '& .section-header__title': {
            fontSize: '40px',
            fontWeight: 600,
          },
          '& .section-header__subtitle': {
            fontSize: '20px !important',
            fontWeight: '300 !important',
          },
          backgroundImage: 'none',
          marginTop: '50px',
          '& .image-wrapper': {
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 0,
            marginTop: '15px',
          },
        },
        '& .section-header__title-wrapper': {
          maxWidth: '500px',
        },
        '& .section-header__subtitle': {
          fontSize: '28px',
          fontWeight: 400,
          maxWidth: 550,
        },
        '& .customIcon': {
          marginRight: '12px'
        }
      },

      '& .faq-contact-header-section': {
        minHeight: '547px',
      }
    },

    /* Static Page Hero Banners */
    '& .dashboard-hero-section, & .terms-hero-section, & .privacy-hero-section, & .hipaa-hero-section':
      {
        backgroundColor: palette.background.header,
      },
    '& .book-appointment-hero-section': {
      height: '100%',
      backgroundColor: palette.background.main,

      '& .MuiTypography-root': {
        color: palette.text.primary,
      },
    },

    /* Book Appointment */
    '& .morning-image, & .noon-image, & .evening-image': {
      fill: `${palette.primary.main} !important`,
    },

    /* Create Subscriber - Checkout flow container */
    '& .checkout-flow-container': {
      '& .find-provider-section, & .plan-selection-section, & .checkout-hero-section':
        {
          background: palette.background.header,
        },
      '& .planCard': {
        '&:hover': {
          color: palette.text.primary,
        },
      },
    },

    /* Create Subscriber - Choose plan */
    '& .plan-selection-container': {
      '& .home-static-hero-wrapper': {
        '& .hero-static-bg': {
          backgroundColor: palette.background.main,
        },
      },
      '& .plans-wrapper': {
        '& .highlight-button': {
          backgroundColor: palette.text.highlight,
        },
        '& .highlight-divider': {
          backgroundColor: palette.text.disabled,
        },
      },
      '& .section-header__title': {
        color: `${palette.text.primary} !important`,
      },
    },

    /* FAQ */
    '& .faq-hero-section': {
      height: '650px',
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundColor: palette.text.highlight,
      marginTop: '70px',
      '& .hero-header-title, .hero-header-subtitle': {
        margin: '0',
        textAlign: 'left',
      },
      '& .header-container': {
        justifyContent: 'left',
        margin: '0',
      },
      '& .hero-header-title': {
        fontSize: 52,
        fontWeight: 700
      },
      '& .hero-header-subtitle': {
        fontSize: 28,
        fontWeight: 400
      },
      '@media screen and (max-width: 900px)': {
        height: '100%',
        marginTop: '60px',
        '& .faq-hero-internal': {
          marginTop: '10px',
          padding: '64px 24px !important',
        },
        '& .hero-header-title': {
          fontSize: 40,
          fontWeight: 700
        },
        '& .hero-header-subtitle': {
          fontSize: 20,
          fontWeight: 400
        },
      },
    },
    '& .faqInnerContainer': {
      '& .accordion__collapsable-text': {
        fontWeight: 400,
        color: palette.text.primaryLight,
      },
      '& .faq-link': {
        fontWeight: 'normal',
      },
      '& .accorion__item-title-container': {
        '& .MuiTypography-subtitle1': {
          fontSize: '20px',
          color: palette.primary.main,
        },
      },
    },
    '& .faq-contact-wrapper': {
      background: palette.background.main,
      '& .faq-contact-section': {
        '& .contact-img-wrapper': {
          width: '64px',
          maxWidth: '64px',
        },
      },
    },

    /* Dashboard */
    '& .benefits-done-wrappper': {
      background: palette.primary.light,
    },
    '& .benefits-done-icon': {
      fill: palette.primary.main,
    },

    /* Create Subscriber - Checkout */
    '& .checkout-outer-container': {
      '& .payment-title-root': {
        marginTop: 0,
      },
      '& .promo-spinner': {
        backgroundColor: 'unset',
      },
    },

    /*Subscription Success */
    '& .success-wrapper': {
      maxWidth: '470px',
    },

    /*loader style */
    '& .plans-loader': {
      color: palette.secondary.main,
    },
  },
};
